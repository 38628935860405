import {
  AssetOrderByValues,
  AssetTypeEnumValues,
  DiscoveredViaValues,
  HostedOnValues,
  ThirdPartyAssetTypeGroupEnumValues,
} from '@dt/graphql-support/enums';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

export default function useTableFIlterUrlParams(enums) {
  const params = useLocation().search;

  const read = () => {
    const urlParams = queryString.parse(params);

    return enums.reduce((parsedParams, filterEnum) => {
      if (urlParams[filterEnum]) {
        parsedParams[filterEnum] = (function (value) {
          const filterFunctions = {
            filter_by_text: value => String(value),
            filter_by_external_id: value => String(value),
            filter_by_asset_type: value =>
              []
                .concat(value)
                .map(x => AssetTypeEnumValues[x])
                .filter(x => !!x),
            filter_by_related_first_party_asset_type: value =>
              []
                .concat(value)
                .map(x => AssetTypeEnumValues[x])
                .filter(x => !!x),
            filter_by_app_type: value =>
              []
                .concat(value)
                .map(x => AssetTypeEnumValues[x])
                .filter(x => !!x),
            filter_by_platform: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_discovered_via: value =>
              []
                .concat(value)
                .map(x => DiscoveredViaValues[x])
                .filter(x => !!x),
            filter_by_asset_group_id: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_no_asset_group: value => (value ? true : null),
            filter_by_vulnerable_to_toolkit_hacks: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_active_protection_status: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_cloud_provider: value =>
              []
                .concat(value)
                .map(x => HostedOnValues[x])
                .filter(x => !!x),
            filter_by_asset_tags: value => String(value),
            filter_by_is_shadow: value => (value ? true : null),
            filter_by_uses_third_party_vendor_id: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_asset_type_group: value =>
              []
                .concat(value)
                .map(x => ThirdPartyAssetTypeGroupEnumValues[x])
                .filter(x => !!x),
            filter_by_related_first_party_asset_id: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_severity: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_has_policy_violations_with_status: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_subscription: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_belongs_to_products: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_current_status: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_priority: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_compliance_policy: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_release_type: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_store_blocker: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_age: value => String(value),
            filter_by_has_policy_violations_created_on_month: value => String(value),
            order_by: value => AssetOrderByValues[String(value)],
            filter_by_team_name: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_workspace_name: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_tests_by_status: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_user_id: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_sast_issue_type: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_type: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_hacker_toolkit: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
            filter_by_is_priority_alert: value =>
              []
                .concat(value)
                .map(x => String(x))
                .filter(x => !!x),
          };
          return filterFunctions[filterEnum](value);
        })(urlParams[filterEnum]);
      }
      return parsedParams;
    }, {});
  };

  const write = filters => {
    const parsedParams = enums.reduce((parsedParams, filterEnum) => {
      if (filterEnum === 'filter_by_text') {
        parsedParams[filterEnum] = filters[filterEnum];
      }
      if (filterEnum === 'filter_by_asset_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_related_first_party_asset_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_app_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_platform') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_discovered_via') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_asset_group_id') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_no_asset_group') {
        parsedParams[filterEnum] = filters[filterEnum] ? String(true) : null;
      }
      if (filterEnum === 'filter_by_vulnerable_to_toolkit_hacks') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_active_protection_status') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_cloud_provider') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_asset_tags') {
        parsedParams[filterEnum] = filters[filterEnum];
      }
      if (filterEnum === 'filter_by_is_shadow') {
        parsedParams[filterEnum] = filters[filterEnum] ? String(true) : null;
      }
      if (filterEnum === 'filter_by_related_first_party_asset_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_uses_third_party_vendor_id') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_asset_type_group') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_related_first_party_asset_id') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_severity') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }

      if (filterEnum === 'order_by') {
        parsedParams[filterEnum] = String(filters[filterEnum]);
      }
      if (filterEnum === 'filter_by_severity') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_has_policy_violations_with_status') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_subscription') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_belongs_to_products') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_current_status') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_priority') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_compliance_policy') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_release_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_store_blocker') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_age') {
        parsedParams[filterEnum] = filters[filterEnum];
      }
      if (filterEnum === 'filter_by_has_policy_violations_created_on_month') {
        parsedParams[filterEnum] = filters[filterEnum];
      }
      if (filterEnum === 'filter_by_team_name') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_workspace_name') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_tests_by_status') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_user_id') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_sast_issue_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_type') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }
      if (filterEnum === 'filter_by_hacker_toolkit') {
        parsedParams[filterEnum] = filters[filterEnum]?.map(x => String(x ?? ''));
      }

      return parsedParams;
    }, queryString.parse(window.location.search));

    // Construct url with arguments.
    const existingParams = decodeURI(window.location.search)
      .replace('?', '')
      .split('&')
      .map(param => param.split('='))
      .reduce((values, [key, value]) => {
        values[key] = value;
        return values;
      }, {});

    const query = queryString.stringify(
      {
        ...existingParams,
        ...parsedParams,
      },
      { skipNull: true },
    );

    const { protocol, host, pathname } = window.location;
    const url = `${protocol}//${host}${pathname}?${query}`;
    window.history.replaceState({ path: url }, '', url);
  };

  return {
    read,
    write,
  };
}
