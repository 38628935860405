export var downloadFile = function (_a) {
    var _b, _c;
    var fileName = _a.fileName, url = _a.url, openLinkInNewTab = _a.openLinkInNewTab;
    var link = document.createElement('a');
    if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        openLinkInNewTab && link.setAttribute('target', '_blank');
        link.style.visibility = 'hidden';
        (_b = document.body) === null || _b === void 0 ? void 0 : _b.appendChild(link);
        link.click();
        (_c = document.body) === null || _c === void 0 ? void 0 : _c.removeChild(link);
    }
};
